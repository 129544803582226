import React from 'react'

function Aboutcareers() {
    return (
        <>
            <section className="career-about pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-xl-start text-center">
                            <img src="assets/images/resources/career-about-1-1.jpg" className="img-responsive wow fadeInLeft" data-wow-duration="1500ms" alt="" />
                        </div>
                        <div className="col-lg-6">
                            <div className="career-about__content">
                                <div className="block-title text-left">
                                    <p className="block-title__tagline">About Company</p>
                                    <h2 className="block-title__title">Five Years, Millions of Milestones</h2>
                                </div>
                                <p className="career-about__highlight">More Than Just a Loan, We are Partners.</p>

                                <p className="career-about__text">{process.env.REACT_APP_COMPANY_NAME} Loans has been proudly serving our community's lending needs since 2018. Founded by<a href="https://www.crunchbase.com/person/hayes-barnard">Hayes Barnard</a>, our company is committed to helping our customers achieve their financial goals through responsible lending. Over the past 5 years, we have assisted hundreds of individuals and businesses in obtaining loans to purchase homes, vehicles, cover wedding expenses, invest in property, and expand their companies.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Aboutcareers