import React from 'react'
import { Link } from 'react-router-dom'

function Testtwo() {

    const testimonials = [
        {
          image: 'assets/images/resources/testi-1-1.png',
          name: 'Delia Riley',
          designation: 'Business Owner',
          stars: [1, 2, 3, 4, 5],
          text:
            `As a small business owner, it was difficult for me to get a business loan from traditional banks.  ${process.env.REACT_APP_COMPANY_NAME} worked closely with me and got me the financing I needed to expand my company.`,
        },
        {
          image: 'assets/images/resources/testi-1-2.png',
          name: 'Essie Perez',
          designation: 'First-Time Homebuyer',
          stars: [1, 2, 3, 4, 5],
          text:
            `${process.env.REACT_APP_COMPANY_NAME} made buying my first home so easy. Their low down payment program allowed me to finally achieve my dream of homeownership.`,
        },
        {
          image: 'assets/images/resources/testi-1-3.png',
          name: 'Dustin Dunn',
          designation: 'Vet.',
          stars: [1, 2, 3, 4, 5],
          text:
            `The debt consolidation loan I got from ${process.env.REACT_APP_COMPANY_NAME} was a total lifesaver. By combining all my high interest credit card balances into one manageable payment, they saved me money on interest and simplified my finances.`,
        },
      ];

    return (
        <>
            <section className="call-to-action-four text-center wow fadeInUp" data-wow-duration="1500ms">
                <div className="container">
                    <div className="call-to-action-four__inner">
                        <div className="call-to-action-four__shape-1"></div>
                        <div className="call-to-action-four__shape-2"></div>
                        <p className="call-to-action-four__text">
                            We will customize a loan based on the amount of cash you need.
                            <Link to="/contact">Contact Now</Link>
                        </p>
                    </div>
                </div>
            </section>

             <section className="testimonials-one pt-120 pb-120">
                <div className="container">
                <div className="block-title text-center">
                    <p className="block-title__tagline">our testimonials</p>
                    <h2 className="block-title__title">
                    What they’re talking about <br /> {process.env.REACT_APP_COMPANY_NAME}
                    </h2>
                </div>
                <div className="row row-gutter-y-30">
                    {testimonials.map((testimonial, index) => (
                    <div
                        className="col-lg-4 col-md-12 wow fadeInUp"
                        data-wow-duration="1500ms"
                        data-wow-delay={`${index * 100}ms`}
                        key={index}
                    >
                        <div className="testimonial-card">
                        <div className="testimonial-card__info">
                            <div className="testimonial-card__image">
                            <img src={testimonial.image} alt={testimonial.name} />
                            </div>
                            <div className="testimonial-card__meta">
                            <div className="testimonial-card__stars">
                                {testimonial.stars.map((_, starIndex) => (
                                <i className="fa fa-star" key={starIndex}></i>
                                ))}
                            </div>
                            <h3 className="testimonial-card__name">
                                {testimonial.name}
                            </h3>
                            <p className="testimonial-card__designation">
                                {testimonial.designation}
                            </p>
                            </div>
                        </div>
                        <span className="testimonial-card__line"></span>
                        <div className="testimonial-card__text">
                            <p>{testimonial.text}</p>
                        </div>
                        </div>
                    </div>
                    ))}
                </div>
                </div>
            </section>
        </>
    )
}

export default Testtwo