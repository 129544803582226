import React from 'react'
import Bredcom from '../../Bredcom/Main'
import First from '../Menteam/First'


const Team = () => {
  return (
     <>
        <Bredcom title={"Home"} subtitle={"TEAM"} link={"Our Team"}/>
        <First/>
    </>
  )
}

export default Team